// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';
import Link from '../../components/link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    PA260513: ImageType,
    PA260517: ImageType,
    PA260518: ImageType,
    PA260529: ImageType,
    PA260534: ImageType,
    PA260548: ImageType,
    PA260558: ImageType,
    PA260577: ImageType,
    PA260595: ImageType,
  },
};

class Blog_2018_10_26_Spencer extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3BIII" title={frontMatter.title} />
        <h2>Escalante Canyon Rendezvous 2018</h2>
        <p>
          The rendezvous kicked off with a trip down full{' '}
          <Link to="http://ropewiki.com/Spencer_Canyon">Spencer Canyon</Link>.
          This was a new canyon to me and the last of the main Escalante canyons
          I needed to descend. The team was Vickie, Preston, Neil, and me. After
          an introduction and safety briefing, we were off.
        </p>
        <Image
          image={data.PA260513}
          caption={
            <>
              Vickie showing us how to do a{' '}
              <GlossaryLink>soft start</GlossaryLink> when rapping off a{' '}
              <GlossaryLink>deadman anchor</GlossaryLink>.
            </>
          }
        />
        <p>
          Getting through the AFC section went quickly and we{' '}
          <GlossaryLink id="wetsuit">suited</GlossaryLink> up for the mandatory
          water in the POC section.
        </p>
        <Image
          image={data.PA260517}
          caption="Crossroads of AFC, KFC, and POC. One can also enter/exit the canyon from this point."
        />
        <Image
          image={data.PA260518}
          caption="Pretty much the closest thing to a group shot I got."
        />
        The POC section has one rappel right at the beginning. After that it was
        just cruising through with a bunch of down climbs and swims.
        <Image
          image={data.PA260529}
          caption="Earlier in the day Rick, the rendezvous organizer, said the water was all clean and clear. Vickie realizes the hard way that this wasn't completely true."
        />
        <Image
          image={data.PA260534}
          caption="Neil and Preston coming out the narrows"
        />
        <Image
          image={data.PA260548}
          caption="Cool sand in one of the open sections"
        />
        <Image image={data.PA260558} />
        <Image image={data.PA260577} />
        <Image
          image={data.PA260595}
          caption={
            <>
              There were a ton of small{' '}
              <GlossaryLink>Moqui marbles</GlossaryLink> in and around this
              canyon.
            </>
          }
        />
        <p>
          I would <em>love</em> to talk about the other canyons that I did this
          weekend. Interested in knowing what canyons I did? Go to the next
          rendezvous and I'll take you there!
        </p>
        <AllImagesLink id="2018-10-26-spencer" />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-10-26-spencer')(Blog_2018_10_26_Spencer);

export const query = graphql`
  query {
    PA260513: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260513.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260517: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260517.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260518: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260518.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260529: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260529.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260534: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260534.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260548: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260548.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260558: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260558.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260577: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260577.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PA260595: file(
      relativePath: { eq: "images/blog/2018-10-26-spencer/PA260595.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
